import React from 'react'
import cx from 'classnames'
import Button from 'components/Button'
import ImageGrid from 'components/ImageGrid'
import Img from 'gatsby-image'
import '../components/fullstory.js'
import { graphql, useStaticQuery, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import styles from './index.module.scss'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      companies: file(relativePath: { eq: "companies.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      metrocard: file(relativePath: { eq: "metrocard.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    // <Layout
    //   className={styles.page}
    //   banner={
    //     <div className="content">
    //       Code Cooperative is now Emergent Works.{" "}
    //       <Link to="/news/we-are-emergent-works">Learn more</Link>
    //     </div>
    //   }
    // >
    <Layout className={styles.page}>
      <SEO title="Home" />

      <section className={cx(styles.root, "homeHead")}>
        <div className="content">
          <div className={styles.gridContainer12}>
            <h1 className={cx(styles.gridCol12, styles.title)}>
              We're all in this{" "}
              <span className={styles.boldColor}>together</span>.
            </h1>
          </div>
          <div className={styles.gridContainer12}>
            <h2 className={styles.gridColLeft}>
              We fight hunger and food insecurity collaboratively in the Twin
              Cities by distributing thousands of meals daily to all community
              members who need support.
            </h2>
          </div>
        </div>
      </section>

      <section className={cx(styles.imageGrid, "mt--xl")}>
        <div
          className="mt--xl"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <iframe
            title="Welcome"
            src="https://player.vimeo.com/video/529218994?autoplay=1&title=0&byline=0&portrait=0&muted=1&loop=1"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameBorder="0"
            allow="autoplay; fullscreen"
          ></iframe>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </section>

      <section className="content">
        <div className={styles.gridContainer12}>
          <h2 className={cx(styles.gridCol11, "mt--xl")}>
            Feeding Community is a grassroots initiative to provide nutritious,
            culturally appropriate meals to our most vulnerable community
            members in the Twin Cities metro area.
          </h2>
        </div>
        <div className={styles.gridContainer12}>
          <h2 className={styles.gridColLeft}>
            <span className={cx("bold", styles.boldColor)}>
              With the help of partners and volunteers, we've distributed over
              1,200,000+ meals to neighbors since March 2020.
            </span>
          </h2>
        </div>

        <div>
          <Button className="mt--lg" href="/about">
            Learn More
          </Button>
        </div>
      </section>

      <section className={cx(styles.imageGrid, "mt--xxl")}>
        <ImageGrid />
      </section>

      <section className={cx(styles.gridContainer12, "content")}>
        <div className={styles.gridColLeft}>
          {/* <h2 className="mt--xxl">
             Love, one meal at a time
          </h2>
          <p className="mt--lg">
            We started as a response to the Covid19 pandemic and the need for healthy, halal meals. Now, we serve over 1,000 community members daily, from seniors to families, with culturally-appropriate meals from our kitchens. . public health crisis has e
          </p> */}

          <h2 className="mt--xxl font-weight-bold">Donations support our work.</h2>
          <p className="mt--lg">
            As a grassroots initiative, we need resources to keep distributing
            meals to as many community members as possible. We have partnered with <a href="https://www.2harvest.org/who--how-we-help/services-and-programs/minnesota-central-kitchen.html" style={{color: "#FF9C42",}}>MN Central Kitchen and
            Second Harvest Heartland</a> to increase your donation's impact. Every dollar will
            make a big difference!
          </p>
          <Button to="https://secure.2harvest.org/site/Donation2?df_id=24589&24589.donation=form1#.Yi14jRPMKdZ" className="mt--lg">
            Donate Now
          </Button>
        </div>
        <div className={styles.gridColRight}>
          {/* <img
            src="https://res.cloudinary.com/afrodeli/image/upload/v1611645520/afro-meals_zzs3sp.jpg"
            className={styles.metrocard}
            // fluid={data.metrocard.childImageSharp.fluid}
            alt="Metrocard"
          /> */}
        </div>
      </section>

      {/* <h3 className={styles.companies}>
        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611637041/fc-homemeals_u5zqht.jpg"
          alt="KICKSTARTER · BITLY · YIELDMOÉ GIPHY · DROPBOX · CONDÉ NAST BOX · NEARFORM · AXIOS · GROUP NINE MEDIA · FLAWLESS RIPPLEMATCH · SPRINKLR"
        />
      </h3> */}
    </Layout>
  )
}

export default IndexPage
